import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import ProviderInfo from "../../components/ProviderInfo";

import zia from "../../img/zia.jpg";

const ZiaFatemiMd = () => {
  return (
    <Layout>
      <PageHeader text="Zia Fatemi, MD" />
      <ProviderInfo header="About Dr. Fatemi" image={zia} forceExpand>
        <p className="pt-5">
          Zia Fatemi, MD, is board certified in internal medicine, dedicated to
          thorough treatment and care at Sunstate Medical Associates in Lake
          Mary, Florida.
        </p>
        <p className="mt-6">
          Dr. Fatemi received his medical degree from Shiraz University of
          Medical Sciences in Shiraz, Iran. He went on to complete an internship
          at Northeast Ohio Medical Universities College of Medicine in
          Rootstown, Ohio, followed by a residency at Mt. Sinai Medical Center -
          Case Western Reserve University in Cleveland, Ohio.
        </p>
        <p className="mt-6">
          Dr. Fatemi is a diplomate of the American Board of Internal Medicine
          and has been honored with the Physician’s Recognition Award by the
          American Medical Association for his dedication to patient care. He
          has also been elected as the chief of the department of internal
          medicine and the chair of the peer review committee at Central Florida
          Regional Hospital.
        </p>
        <p className="mt-6">
          After practicing as an internist in California, he later moved to
          Florida for a role as the medical director of the largest hospitalist
          program in Central Florida.
        </p>
        <p className="mt-6">
          Dr. Fatemi has a strong background in outpatient and hospital care and
          is recognized by his peers as a dedicated physician and compassionate
          human being. He enjoys serving patients alongside his wife, Dr.
          Farideh A. Zadeh, at Sunstate Medical Associates.
        </p>
      </ProviderInfo>
    </Layout>
  );
};

export default ZiaFatemiMd;
